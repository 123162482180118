/* eslint-disable import/no-relative-parent-imports */
import React, { useState, useContext, useEffect } from "react";
import { ActiveOrgContext } from "App";
import { createRxMap } from "endpoints/rxMaps";
import { toast } from "material-react-toastify";
import * as turf from "@turf/turf";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  makeStyles
} from "@material-ui/core";
import { useQuery } from "react-query";
import { getUserSamplings, getLabForZoneSampling } from "endpoints/labData";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2)
  },
  dialogActions: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

const CreateRxMapModal = ({ open, handleClose, plotData, refecthRxMaps }) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedLabData, setSelectedLabData] = useState("");
  const [mapName, setMapName] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [cropType, setCropType] = useState("");
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [amount, setAmount] = useState(filteredFeatures.map(() => ""));
  const [amountOfSeeds, setAmountOfSeeds] = useState(
    filteredFeatures.map(() => "")
  );
  const [seedBrand, setSeedBrand] = useState(filteredFeatures.map(() => ""));
  const [fertilizerType, setFertilizerType] = useState(
    filteredFeatures.map(() => "")
  );
  const [targetedYield, setTargetedYield] = useState(
    filteredFeatures.map(() => 0)
  );
  const [lackingValues, setLackingValues] = useState(
    filteredFeatures.map(() => ({ N: 0, P: 0, K: 0 }))
  );
  const [userOrgContextValue] = useContext(ActiveOrgContext);

  const labValues = [
    "pH",
    "EC",
    "CEC",
    "Humus",
    "Karbonati",
    "Bazna",
    "CaMg",
    "N",
    "S",
    "P",
    "K",
    "Ca",
    "Mg",
    "B",
    "Fe",
    "Cu",
    "Mn",
    "Zn",
    "Mo",
    "Na"
  ];

  const targetedYieldCropValues = {
    Corn: {
      N: 20,
      P: 9.2,
      K: 24.5
    },
    Sunflower: {
      N: 45,
      P: 13.7,
      K: 26
    },
    Wheat: {
      N: 31,
      P: 10.7,
      K: 24.8
    },
    Ryegrass: {
      N: 39,
      P: 12,
      K: 32.5
    },
    Barley: {
      N: 29.3,
      P: 11.6,
      K: 31.7
    },
    Oat: {
      N: 33.7,
      P: 13.8,
      K: 34.9
    },
    Canola: {
      N: 54.8,
      P: 26.2,
      K: 50.8
    },
    Sugarbeet: {
      N: 1.9,
      P: 1.1,
      K: 3.7
    },
    Sorghum: {
      N: 13,
      P: 7.8,
      K: 5.4
    },
    Soybean: {
      N: 55,
      P: 16,
      K: 37
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedOption("");
    setMapName("");
    setSelectedLabData("");
    setCropType("");
    setFilteredFeatures([]);
    setTargetedYield([]);
    setLackingValues([]);
    setAmount([]);
    setFertilizerType(filteredFeatures.map(() => ""));
    setAmountOfSeeds(filteredFeatures.map(() => ""));
    setSeedBrand(filteredFeatures.map(() => ""));
  };

  const handleFertilizerTypeChange = (value, index) => {
    const updatedFertilizerTypes = [...fertilizerType];
    updatedFertilizerTypes[index] = value;
    setFertilizerType(updatedFertilizerTypes);
  };

  const handleAmountChange = (value, index) => {
    const updatedAmounts = [...amount];
    updatedAmounts[index] = value;
    setAmount(updatedAmounts);
  };

  const handleSeedAmountChange = (value, index) => {
    const updatedSeedAmounts = [...amountOfSeeds];
    updatedSeedAmounts[index] = value;
    setAmountOfSeeds(updatedSeedAmounts);
  };

  const handleSeedBrandChange = (value, index) => {
    const updatedSeedBrand = [...seedBrand];
    updatedSeedBrand[index] = value;
    setSeedBrand(updatedSeedBrand);
  };

  const generateFilteredFeatures = features => {
    return features.filter(feature => {
      const hasMatchingProperties = labValues.some(
        labValue => feature.properties[labValue] !== undefined
      );

      return hasMatchingProperties;
    });
  };

  const generateFilteredFeaturesFromPlotData = plotDataFeatures => {
    const colorMap = new Map();

    for (const feature of plotDataFeatures) {
      const fillColor =
        feature.properties.FillColor || feature.properties.fillColor;

      if (colorMap.has(fillColor)) {
        colorMap.get(fillColor).properties.area +=
          calculateZoneArea(feature) / 10000;
      } else {
        const clonedFeature = JSON.parse(JSON.stringify(feature));
        clonedFeature.properties.area = calculateZoneArea(feature) / 10000;
        colorMap.set(fillColor, clonedFeature);
      }
    }

    return Array.from(colorMap.values());
  };

  const handleSave = async() => {
    const size = selectedLabData
      ? parseFloat((turf.area(selectedLabData.geoData) * 0.0001).toFixed(2))
      : parseFloat((turf.area(plotData.geoData) * 0.0001).toFixed(2));
    const organizationId = userOrgContextValue.id;
    const fieldId = plotData.id;
    // const zoneSamplingId = selectedOption ? selectedOption.id : null;
    // do we even need this param??
    const zoneSamplingId = 'test';
    const type = selectedTab === 0 ? "fertilizer" : "seeds";
    const labDataId = "test";

    let selectedGeoData;
    let featuresToUse;

    if (selectedLabData) {
      selectedGeoData = selectedLabData.geoData;
      featuresToUse = generateFilteredFeatures(selectedGeoData.features);
    } else {
      featuresToUse = generateFilteredFeaturesFromPlotData(
        plotData.geoData.features
      );
      selectedGeoData = plotData.geoData;
    }

    const updatedGeoData = selectedGeoData.features.map((feature, index) => {
      const fillColor =
        feature.properties.FillColor || feature.properties.fillColor;

      const featureIndex = featuresToUse.findIndex(filteredFeature => {
        const filteredColor =
          filteredFeature.properties.FillColor ||
          filteredFeature.properties.fillColor;
        return filteredColor === fillColor;
      });

      if (featureIndex !== -1 && selectedTab === 0) {
        feature.properties["fertilizer_type"] = fertilizerType[featureIndex];
        feature.properties["kg_p_h"] = amount[featureIndex];
      } else if (featureIndex !== -1 && selectedTab === 1) {
        feature.properties["number_of_seeds_p_ha"] =
          amountOfSeeds[featureIndex];
        feature.properties["seed_brand"] = seedBrand[featureIndex];
      }

      return feature;
    });

    try {
      await createRxMap(
        mapName,
        size,
        organizationId,
        { features: updatedGeoData },
        fieldId,
        zoneSamplingId,
        type,
        labDataId
      );
      toast.success("Successfully created RX map!");
      refecthRxMaps();
      handleClose();
    } catch (error) {
      toast.error("Error saving RxMap:", error);
    }
  };

  const { data: zoneSamplings, refetch: zoneSamplingsRefetch } = useQuery(
    ["getUserSamplings"],
    () => getUserSamplings(userOrgContextValue.id, plotData.id),
    {
      enabled: false
    }
  );

  const { data: labData, refetch: labDataRefetch } = useQuery(
    ["getLabForZoneSampling"],
    () => getLabForZoneSampling(selectedOption.id),
    {
      enabled: false
    }
  );

  // save dugme disable tracking
  useEffect(() => {
    const isDisabled =
      !mapName ||
      !fertilizerType.some(type => type !== "") ||
      !amount.some(value => value !== "");

    setIsSaveDisabled(isDisabled);
  }, [mapName, fertilizerType, amount]);

  useEffect(() => {
    const isDisabled =
      !mapName ||
      !amountOfSeeds.some(seedN => seedN !== "") ||
      !seedBrand.some(seedB => seedB !== "");

    setIsSaveDisabled(isDisabled);
  }, [mapName, amountOfSeeds, seedBrand]);

  useEffect(() => {
    if (!open) {
      setSelectedTab(0);
      setSelectedOption("");
      setSelectedLabData("");
      setMapName("");
      setCropType("");
      setFilteredFeatures([]);
      setTargetedYield([]);
      setLackingValues([]);
      setAmount([]);
      setFertilizerType(filteredFeatures.map(() => ""));
      setAmountOfSeeds(filteredFeatures.map(() => ""));
      setSeedBrand(filteredFeatures.map(() => ""));
    } else {
      if (plotData.id) {
        zoneSamplingsRefetch();
      }
      if (selectedOption) {
        labDataRefetch();
      }
    }
  }, [open, plotData.id, selectedOption]);

  useEffect(() => {
    if (
      selectedLabData &&
      selectedLabData.geoData &&
      selectedLabData.geoData.features
    ) {
      const filteredFeatures = selectedLabData.geoData.features.filter(
        feature => {
          return labValues.some(
            labValue => feature.properties[labValue] !== undefined
          );
        }
      );

      setFilteredFeatures(filteredFeatures);
      setTargetedYield(filteredFeatures.map(() => 0));
      setLackingValues(filteredFeatures.map(() => ({ N: 0, P: 0, K: 0 })));
    }
  }, [selectedLabData, cropType]);

  const calculateZoneArea = feature => {
    return turf.area(feature.geometry) / 10000;
  };

  const calculateLacking = (element, inputValue, index) => {
    if (!cropType) {
      return 0;
    }

    if (!targetedYieldCropValues[cropType]) {
      return 0;
    }

    const targetedYieldValue =
      targetedYieldCropValues[cropType][element] * inputValue;
    const currentElementValue =
      filteredFeatures.length && filteredFeatures[index].properties[element]
        ? parseFloat(filteredFeatures[index].properties[element])
        : 0;

    const difference = currentElementValue - targetedYieldValue;
    return -difference;
  };

  const handleTargetedYieldChange = (value, index) => {
    const updatedYields = [...targetedYield];
    updatedYields[index] = value;
    setTargetedYield(updatedYields);

    const lackingNValue = calculateLacking("N", value, index);
    const lackingPValue = calculateLacking("P", value, index);
    const lackingKValue = calculateLacking("K", value, index);

    setLackingValues(prevValues => {
      const newValues = [...prevValues];
      newValues[index] = {
        N: lackingNValue,
        P: lackingPValue,
        K: lackingKValue
      };
      return newValues;
    });
  };

  const renderFertilizerTable = () => {
    let featuresToUse = [];

    if (filteredFeatures.length) {
      featuresToUse = filteredFeatures;
    } else if (plotData && plotData.geoData && plotData.geoData.features) {
      const colorMap = new Map();
      for (const feature of plotData.geoData.features) {
        const fillColor =
          feature.properties.FillColor || feature.properties.fillColor;
        if (colorMap.has(fillColor)) {
          colorMap.get(fillColor).properties.area +=
            calculateZoneArea(feature) / 10000;
        } else {
          const clonedFeature = JSON.parse(JSON.stringify(feature));
          clonedFeature.properties.area = calculateZoneArea(feature) / 10000;
          colorMap.set(fillColor, clonedFeature);
        }
      }
      featuresToUse = Array.from(colorMap.values());
    }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ZONE</TableCell>
              <TableCell>AREA (ha)</TableCell>
              <TableCell>N</TableCell>
              <TableCell>P</TableCell>
              <TableCell>K</TableCell>
              <TableCell>TARGETED YIELD(t/ha)</TableCell>
              <TableCell>LACKING N</TableCell>
              <TableCell>LACKING P</TableCell>
              <TableCell>LACKING K</TableCell>
              <TableCell>TYPE OF FERTILIZER</TableCell>
              <TableCell>AMOUNT (kg/ha)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {featuresToUse?.map((feature, index) => (
              <TableRow key={index}>
                <TableCell>
                  <div
                    style={{
                      width: "30px",
                      height: "20px",
                      backgroundColor:
                        feature.properties.FillColor ||
                        feature.properties.fillColor
                    }}
                  />
                </TableCell>
                <TableCell>
                  {calculateZoneArea(feature).toFixed(2)} ha
                </TableCell>
                <TableCell>{feature?.properties.N || 0}</TableCell>
                <TableCell>{feature?.properties.P || 0}</TableCell>
                <TableCell>{feature?.properties.K || 0}</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    label="Targeted Yield"
                    value={targetedYield[index]}
                    onChange={e =>
                      handleTargetedYieldChange(e.target.value, index)
                    }
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </TableCell>
                <TableCell>
                  {lackingValues[index] ? lackingValues[index].N.toFixed(2) : 0}
                </TableCell>
                <TableCell>
                  {lackingValues[index] ? lackingValues[index].P.toFixed(2) : 0}
                </TableCell>
                <TableCell>
                  {lackingValues[index] ? lackingValues[index].K.toFixed(2) : 0}
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    label="Type of fertilizer"
                    value={fertilizerType[index]}
                    onChange={e =>
                      handleFertilizerTypeChange(e.target.value, index)
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    label="Amount"
                    value={amount[index]}
                    onChange={e => handleAmountChange(e.target.value, index)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderSeedsTable = () => {
    let featuresToUse = [];

    if (filteredFeatures.length) {
      featuresToUse = filteredFeatures;
    } else if (plotData && plotData.geoData && plotData.geoData.features) {
      const colorMap = new Map();
      for (const feature of plotData.geoData.features) {
        const fillColor =
          feature.properties.FillColor || feature.properties.fillColor;
        if (colorMap.has(fillColor)) {
          colorMap.get(fillColor).properties.area +=
            calculateZoneArea(feature) / 10000;
        } else {
          const clonedFeature = JSON.parse(JSON.stringify(feature));
          clonedFeature.properties.area = calculateZoneArea(feature) / 10000;
          colorMap.set(fillColor, clonedFeature);
        }
      }
      featuresToUse = Array.from(colorMap.values());
    }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ZONE</TableCell>
              <TableCell>AREA (ha)</TableCell>
              <TableCell>NUMBER OF SEEDS/HA</TableCell>
              <TableCell>SEED BRAND</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {featuresToUse?.map((feature, index) => (
              <TableRow key={index}>
                <TableCell>
                  <div
                    style={{
                      width: "30px",
                      height: "20px",
                      backgroundColor:
                        feature.properties.FillColor ||
                        feature.properties.fillColor
                    }}
                  />
                </TableCell>
                <TableCell>
                  {calculateZoneArea(feature).toFixed(2)} ha
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    label="Number of seeds"
                    value={amountOfSeeds[index]}
                    onChange={e =>
                      handleSeedAmountChange(e.target.value, index)
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    label="Seed brand"
                    value={amount[index]}
                    onChange={e => handleSeedBrandChange(e.target.value, index)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle className={classes.root}>N P K Variable Rate</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Name of the Map"
              value={mapName}
              onChange={e => setMapName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id="crop-type-select">Crop Type</InputLabel>
              <Select
                labelId="crop-type-select"
                id="crop-type-select"
                value={cropType}
                onChange={e => setCropType(e.target.value)}
              >
                <MenuItem value="Corn">Corn</MenuItem>
                <MenuItem value="Sunflower">Sunflower</MenuItem>
                <MenuItem value="Wheat">Wheat</MenuItem>
                <MenuItem value="Ryegrass">Ryegrass</MenuItem>
                <MenuItem value="Barley">Barley</MenuItem>
                <MenuItem value="Oat">Oat</MenuItem>
                <MenuItem value="Canola">Canola</MenuItem>
                <MenuItem value="Sugar Beet">Sugar Beet</MenuItem>
                <MenuItem value="Sorghum">Sorghum</MenuItem>
                <MenuItem value="Soybean">Soybean</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div className={classes.row}>
          <FormControl className={classes.formControl}>
            <InputLabel id="zone-sampling-data-select">
              Select Zone Sampling
            </InputLabel>
            <Select
              labelId="zone-sampling-data-select"
              id="zone-sampling-data-select"
              value={selectedOption.name}
              onChange={e =>
                setSelectedOption(zoneSamplings?.data[e.target.value])
              }
            >
              {zoneSamplings?.data.map((lab, index) => (
                <MenuItem value={index}>{lab.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="lab-data-select">Select Lab Data</InputLabel>
            <Select
              labelId="lab-data-select-label"
              id="lab-data-select"
              value={selectedLabData.name}
              onChange={e => setSelectedLabData(labData[e.target.value])}
            >
              {labData?.map((lab, index) => (
                <MenuItem value={index}>{lab.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Fertilizer Variable Rate" />
          <Tab label="Seeds Variable Rate" />
        </Tabs>
        {selectedTab === 0 && <div>{renderFertilizerTable()}</div>}
        {selectedTab === 1 && <div>{renderSeedsTable()}</div>}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={isSaveDisabled}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRxMapModal;
