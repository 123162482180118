import axiosInstance from ".";

export const getOrgRxMaps = async({organizationId, fieldId}) => {
  return axiosInstance
    .get(`/rx-maps/${organizationId}?page=1&limit=30&fieldId=${fieldId}`)
    .then(res => res.data);
};

export const getOrgLabData = async({ organizationId }) => {
  return axiosInstance
    .get(`/rx-maps/lab/${organizationId}?page=1&limit=30`)
    .then(res => res.data);
};

export const createRxMap = async(
  name,
  size,
  organizationId,
  geoData,
  fieldId,
  zoneSamplingId,
  type,
  labDataId,
) => {
  const data = { name, size, organizationId, geoData, fieldId, zoneSamplingId, type, labDataId };
  return axiosInstance.post("/rx-maps/create", data).then(res => res.data);
};
