import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Sidebar from "components/Sidebar/Sidebar";
import { ActiveOrgContext } from "App";
import PlotsMap from "views/SoilMapsParse/PlotsMap";
import getAreaWithoutObstacles from "utils/AreaCalc";
import { useQuery } from "react-query";
import { getUserSamplings, getLabForZoneSampling } from "endpoints/labData";
import ParcelSearchBar from "utils/SearchBar";
import UploadExcelFile from "./UploadExcelFile";
import * as turf from "@turf/turf";
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  LinearProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
  bottomButton: {
    position: "sticky",
    bottom: "0",
    width: "100%",
    height: "52px",
    margin: "30px 0 0 0"
  },
  titleBox: {
    backgroundColor: theme.palette.grey[500],
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    color: "white",
    marginBottom: "20px"
  },
  accordion: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  accordionSummary: {
    borderRadius: theme.spacing(1)
  },
  accordionDetails: {
    flexDirection: "column"
  },
  accordionButtonStyle: {
    border: "1px solid gray",
    borderRadius: "5px",
    marginTop: "10px"
  },
  mapContainer: {
    height: "calc(100vh - 128px)",
    overflowY: "scroll"
  },
  accordionCheckbox: {
    position: "absolute",
    top: 2,
    right: 35
  }
}));

const RLabData = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [plotData, setPlotData] = useState("");
  const [addObstacleMode, setAddObstacleMode] = useState(false);
  const [isPlotSelected, setIsPlotSelected] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const anyExpandedAccordion = !!expandedAccordion;
  const [userOrgContextValue] = useContext(ActiveOrgContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlotName, setSelectedPlotName] = useState("");
  const [selectedZoneSampling, setSelectedZoneSampling] = useState();

  const queryInfo = useQuery(["getUserSamplings"], () =>
    getUserSamplings(userOrgContextValue.id)
  );

  const { data, isLoading, refetch } = queryInfo;

  const {
    data: labData,
    loading: labDataLoading,
    refetch: labDataRefetch
  } = useQuery(
    ["getLabForZoneSampling"],
    () => getLabForZoneSampling(selectedZoneSampling),
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (data) {
      const updatedPlot = data.data.find(plot => plot.id === expandedAccordion);
      if (updatedPlot) {
        setSelectedPlot(updatedPlot.geoData);
      }
    }
  }, [data, expandedAccordion]);

  useEffect(() => {
    if (selectedZoneSampling) {
      labDataRefetch();
    }
  }, [selectedZoneSampling, labDataRefetch]);

  const handleAccordionChange = plotId => (event, newExpanded) => {
    setExpandedAccordion(newExpanded ? plotId : false);
  };

  const handlePlotClick = (plotData, plotId) => {
    if (plotData.features) {
      setSelectedPlot(plotData.features);
    } else {
      setSelectedPlot(plotData);
    }
    setIsPlotSelected(prevState =>
      expandedAccordion === plotId ? !prevState : true
    );
    setSelectedZoneSampling(plotId);
  };

  useEffect(() => {
    if (!anyExpandedAccordion) {
      setSelectedPlot("");
    }
  }, [anyExpandedAccordion]);

  const handleSearch = term => {
    setSearchTerm(term);
  };

  const handleExcelModalOpen = plotData => {
    setSelectedPlotName(plotData.name);
    setIsModalOpen(true);
  };

  const handleLabDataClick = plot => {
    if (plot.features) {
      setSelectedPlot(plot.features);
    } else {
      setSelectedPlot(plot);
    }
  };

  const filteredParcels = data?.data?.filter(parcel =>
    parcel.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Sidebar style={{ height: "100vh" }}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box className={classes.titleBox}>
            <Typography variant="h6">Lab data</Typography>
          </Box>
          {isLoading && <LinearProgress />}
          <ParcelSearchBar onSearch={handleSearch} />
          <Box className={classes.mapContainer}>
            {filteredParcels?.map(plot => (
              <Accordion
                key={plot.id}
                className={classes.accordion}
                expanded={expandedAccordion === plot.id}
                onChange={handleAccordionChange(plot.id)}
              >
                <AccordionSummary
                  className={classes.accordionSummary}
                  onClick={() => {
                    handlePlotClick(plot.geoData, plot.id);
                    setPlotData(plot);
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>{plot.name}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Button className={classes.accordionButtonStyle}>
                    Size: {getAreaWithoutObstacles(plot.geoData)} HA
                  </Button>
                  <Button
                    className={classes.accordionButtonStyle}
                    onClick={() => handleExcelModalOpen(plotData)}
                  >
                    Upload a file
                  </Button>
                  {labDataLoading ? (
                    <LinearProgress />
                  ) : (
                    labData?.map(ele => (
                      <Button
                        key={ele.id}
                        className={classes.accordionButtonStyle}
                        onClick={() => handleLabDataClick(ele.geoData)}
                      >
                        {ele.name}
                      </Button>
                    ))
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Sidebar>
      <UploadExcelFile
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        plotName={selectedPlotName}
        samplingId={plotData.id}
        labDataRefetch={labDataRefetch}
      />
      <PlotsMap
        selectedPlot={selectedPlot}
        refetch={refetch}
        addObstacleMode={addObstacleMode}
        plotData={plotData}
        setAddObstacleMode={setAddObstacleMode}
        isPlotSelected={isPlotSelected}
        allPlotsData={data?.data}
      />
    </>
  );
};

export default RLabData;
