/* eslint-disable no-magic-numbers */
/* eslint-disable no-undefined */
import React, { useState, useEffect, useRef, useCallback, useContext, useMemo } from 'react';
import {
  GoogleMap,
  Marker,
  Polyline,
  useLoadScript,
  Polygon,
  Circle,
  InfoWindow,
} from '@react-google-maps/api';
import getColorAreas from 'utils/Legend';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { ActiveOrgContext } from 'App';
import Legend from 'views/SoilMaps/Legend';
import * as turf from "@turf/turf";
import convertShapeToGeoJSON from 'utils/shape';
import { createMap } from 'endpoints/fieldMaps';
import DrawingModal from './DrawingModal';
import { Page } from 'components';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'material-react-toastify';
import getAreaWithoutObstacles from 'utils/AreaCalc';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
}));

const libraries = ['drawing'];

const PlotsMap = ({ selectedPlot, refetch, addObstacleMode, plotData, isNavigatorMap, setAddObstacleMode, isPlotSelected, isRxMap, isRxMapClicked }) => {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [drawnShape, setDrawnShape] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [polylines, setPolylines] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [circles, setCircles] = useState([]);
  const [obstacle, setObstacle] = useState([]);
  const [infoWindows, setInfoWindows] = useState([]);
  const [userOrgContextValue] = useContext(ActiveOrgContext);
  const [hoveredInfoWindow, setHoveredInfoWindow] = useState(null);
  const mapRef = useRef();
  const drawingManagerRef = useRef(null);
  const classes = useStyles();

  const labValues = ['pH', 'EC', 'CEC', 'Humus', 'Karbonati' , 'Bazna' , 'CaMg', 'N', 'S', 'P', 'K', 'Ca', 'Mg', 'B', 'Fe', 'Cu', 'Mn', 'Zn', 'Mo', 'Na'];
  const legendData = getColorAreas(selectedPlot);


  useEffect(() => {
  if (selectedPlot && Array.isArray(selectedPlot)) {
    const newInfoWindows = selectedPlot
      .filter((parcel) => {
        for (const labValue of labValues) {
          if (parcel.properties && parcel.properties[labValue] !== undefined) {
            return true;
          }
        }
        return false;
      })
      .map((parcel, index) => {
        const coordinates = parcel.geometry.coordinates;
        const type = parcel.geometry.type;
        const centroid =
          type !== 'Point' &&
          turf.centroid({
            type: 'Feature',
            geometry: {
              type: type,
              coordinates: coordinates,
            },
          });
        const areaInHa = (turf.area(parcel.geometry) * 0.0001).toFixed(2);
        const areaColor = parcel.properties.fillColor || parcel.properties.FillColor;

        const rowAverages = {};

        labValues.forEach((labValue) => {
          if (parcel.properties && parcel.properties[labValue] !== undefined) {
            const labSum = selectedPlot
              .filter((p) => p.properties && p.properties[labValue] !== undefined)
              .reduce((sum, p) => sum + p.properties[labValue], 0);

            const labCount = selectedPlot.filter((p) => p.properties && p.properties[labValue] !== undefined).length;

            rowAverages[labValue] = (labSum / labCount).toFixed(2);
          }
        });

        const calculateValue = (labValue, labData) => {
          const nutrientFactors = {
            N: 1200,
            P: 1200,
            K: 1200,
            Ca: 1200,
            Mg: 1200,
            Fe: 1200,
            Zn: 1200,
            Na: 1200,
            Cu: 1200,
            B: 1200,
            Mn: 1200,
            Mo: 1200,
          };

          if (nutrientFactors.hasOwnProperty(labValue) && labData[labValue] !== undefined) {
            return (((labData[labValue] * nutrientFactors[labValue]) / 1000000) * 3000).toFixed(2);
          } else {
            return 'n/a';
          }
        };


        return (
          <InfoWindow
            key={parcel.id}
            position={{
              lat: centroid.geometry.coordinates[1],
              lng: centroid.geometry.coordinates[0],
            }}
          >
            <div>
              <Button onClick={() => setHoveredInfoWindow(hoveredInfoWindow === null ? index : null)} style={{zIndex: 1500}}>{hoveredInfoWindow === index ? "Hide" : "Show"}</Button>
              {hoveredInfoWindow === index && (
                <div>
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    <h1>SAMPLE POINT RESULT</h1>
                  </div>
                  <div style={{ marginBottom: '20px', fontSize: '20px' }}>
                    <strong>Field:</strong> {plotData.name}
                  </div>
                  <div style={{ marginBottom: '20px', fontSize: '20px' }}>
                    <strong>Zone area (ha):</strong> {areaInHa}
                  </div>
                  <div style={{ marginBottom: '20px', fontSize: '20px', display: 'flex' }}>
                    <strong>Zone color: </strong>{' '}
                    <div style={{ width: '30px', height: '20px', background: areaColor, marginLeft: '10px' }}></div>
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Zone Value</TableCell>
                          <TableCell>Field Average</TableCell>
                          <TableCell>Index</TableCell>
                          <TableCell>Zone value in kg/ha</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {labValues
                          .filter((labValue) => parcel.properties && parcel.properties[labValue] !== undefined)
                          .map((labValue) => (
                            <TableRow key={labValue}>
                              <TableCell>{labValue}</TableCell>
                              <TableCell>{parcel.properties[labValue]}</TableCell>
                              <TableCell>{rowAverages[labValue]}</TableCell>
                              <TableCell>{(parcel.properties[labValue]/rowAverages[labValue]*100).toFixed(2)}</TableCell>
                              <TableCell>{calculateValue(labValue, parcel.properties)}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div>
          </InfoWindow>
        );
      });

    setInfoWindows(newInfoWindows);
  }
}, [isPlotSelected, selectedPlot, hoveredInfoWindow]);

  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  const onPolygonComplete = polygon => {
    if (!addObstacleMode) {
      if (drawingManagerRef.current) {
        drawingManagerRef.current.setOptions({
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_LEFT,
            drawingModes: [
              window.google.maps.drawing.OverlayType.MARKER,
              window.google.maps.drawing.OverlayType.POLYGON,
              window.google.maps.drawing.OverlayType.POLYLINE,
              window.google.maps.drawing.OverlayType.RECTANGLE,
            ],
          },
        });
      }
    }
  };

  const onObstaclePoplygonComplete = polygon => {
    if (addObstacleMode) {
      const paths = polygon
        .getPaths()
        .getArray()
        .map(path => {
          return path.getArray().map(coord => [coord.lng(), coord.lat()]);
        });
      const obstacleGeoJSON = {
        type: 'Feature',
        properties: {
          FillColor: 'Yellow',
          FillOpacity: 0.3,
          BorderColor: '#FF0000',
          BorderWidth: 1,
          name: '',
          class: 'obstacle',
          },
          geometry: {
            type: 'Polygon',
            coordinates: paths,
          },
      };
      if (Array.isArray(selectedPlot)) {
        setObstacle(() => [ obstacleGeoJSON]);
      } else if (typeof selectedPlot === 'object' && selectedPlot !== null) {
        setObstacle(() => [obstacleGeoJSON]);
      } else {
        setObstacle(() => [obstacleGeoJSON]);
      }
    }
  };

  const onOverlayComplete = event => {
    if (!addObstacleMode) {
      setDrawnShape(event.overlay);
      setModalOpen(true);
    }
  };


  const getPolygonCoords = coordinates => {
    if (Array.isArray(coordinates) && Array.isArray(coordinates[0])) {
      if (
        typeof coordinates[0][0] === 'number' &&
        typeof coordinates[0][1] === 'number'
      ) {
        const polygonCoords = coordinates.map(coord => ({
          lat: coord[1],
          lng: coord[0],
        }));
        return polygonCoords;
      } else if (
        Array.isArray(coordinates[0][0]) &&
        typeof coordinates[0][0][0] === 'number' &&
        typeof coordinates[0][0][1] === 'number'
      ) {
        const polygonCoords = coordinates[0].map(coord => ({
          lat: coord[1],
          lng: coord[0],
        }));
        return polygonCoords;
      }
    }

    console.error('Invalid Polygon coordinates structure');
    console.error('coordinates: ', coordinates);
    return [];
  };

  const handleGeometry = useCallback(geometry => {
    if (!geometry?.type) {
      toast.error('Invalid geometry data');
      return [];
    }

    switch (geometry.type) {
      case 'Polygon':
        return getPolygonCoords(geometry.coordinates);
      case 'Point':
        return [getLatLng(geometry.coordinates)];
      case 'LineString':
        return geometry.coordinates[0].map(coord => getLatLng(coord));
      case 'MultiPolygon':
        return geometry.coordinates.flatMap(polygon =>
          getPolygonCoords(polygon),
        );
      default:
        toast.error('Unsupported geometry type: ' + geometry.type);
        return [];
    }
  }, []);

  useEffect(() => {
    if (selectedPlot) {
      const polygons = [];
      const markers = [];
      const circles = [];
      const bounds = new window.google.maps.LatLngBounds();

      if (Array.isArray(selectedPlot)) {
        selectedPlot[0].forEach(feature => {
          const coords = handleGeometry(feature.geometry);
          if (feature.geometry.type === 'Point') {
            const position = handleGeometry(feature.geometry);
            if (feature.properties.class === 'circle') {
              const circle = new window.google.maps.Circle({
                center: coords[0],
                radius: feature.properties.radius,
                map: map,
              });
              circles.push(circle);
              bounds.extend(coords[0]);
            }
            if (position) {
              const marker = new window.google.maps.Marker({ position: position });
              markers.push(marker);
              bounds.extend(position);
            }
          } else {
            const coords = handleGeometry(feature.geometry);
            if (coords.length > 0) {
              const polygon = new window.google.maps.Polygon({ paths: coords });
              polygons.push(polygon);
              coords.forEach(coord => bounds.extend(coord));
            }
          }
        });
      } else {
        if (selectedPlot.geometry.type === 'Point') {
          const position = handleGeometry(selectedPlot.geometry);
          if (position) {
            const marker = new window.google.maps.Marker({ position: position });
            markers.push(marker);
            bounds.extend(position);
          }
        } else {
          const coords = handleGeometry(selectedPlot.geometry);
          if (coords.length > 0) {
            const polygon = new window.google.maps.Polygon({ paths: coords });
            polygons.push(polygon);
            coords.forEach(coord => bounds.extend(coord));
          }
        }
      }

      if (polygons.length > 0) {
        polygons.forEach(polygon => polygon.setMap(map));
        fitMapToBounds(bounds);
      }

      if (markers.length > 0) {
        markers.forEach(marker => marker.setMap(map));
        fitMapToBounds(bounds);
      }
      if (circles.length > 0) {
        circles.forEach(circle => circle.setMap(map));
        fitMapToBounds(bounds);
      }

      if (polygons.length === 0 && markers.length === 0) {
        toast.error('No valid shapes or points found');
      }
    }
  }, []);

  useEffect(() => {
    polygons.forEach(polygon => {
      polygon.setMap(null);
      window.google.maps.event.clearListeners(polygon, 'click');
    });
    polylines.forEach(polyline => {
      polyline.setMap(null);
      window.google.maps.event.clearListeners(polyline, 'click');
    });
    markers.forEach(marker => {
      marker.setMap(null);
      window.google.maps.event.clearListeners(marker, 'click');
    });
    circles.forEach(circle => {
      circle.setMap(null);
      window.google.maps.event.clearListeners(circle, 'click');
    });

    setPolygons([]);
    setPolylines([]);
    setMarkers([]);
    setCircles([]);

    if (selectedPlot && isLoaded && map) {
      const newPolygons = [];
      const newPolylines = [];
      const newMarkers = [];
      const newCircles = [];
      const bounds = new window.google.maps.LatLngBounds();

      if (!Array.isArray(selectedPlot.features)) {
        selectedPlot.forEach(feature => {
          const coords = handleGeometry(feature.geometry);

          if (coords.length > 0) {
            let fillColor = 'black';
            let borderColor = '#000000';
            let fillOpacity = 0.3;
            let borderOpacity = 0.5;

            if (feature.properties) {
              if (feature.properties.FillColor) {
                fillColor = feature.properties.FillColor;
              }
              if (feature.properties.BorderColor) {
                borderColor = feature.properties.BorderColor;
              }
              if (feature.properties.FillColorOpacity) {
                fillOpacity = parseFloat(feature.properties.FillColorOpacity);
              }
              if (feature.properties.BorderColorOpacity) {
                borderOpacity = parseFloat(feature.properties.BorderColorOpacity);
              }
            }

            if (feature.geometry.type === 'Point') {
              if (feature.properties.class === 'circle') {
                const circle = new window.google.maps.Circle({
                  center: coords[0],
                  radius: feature.properties.radius,
                  map: map,
                  fillColor: fillColor,
                  fillOpacity: fillOpacity,
                  strokeColor: borderColor,
                  strokeOpacity: borderOpacity,
                });
                newCircles.push(circle);
                bounds.extend(coords[0]);
              } else {
                const marker = new window.google.maps.Marker({
                  position: coords[0],
                  icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 5,
                    fillColor: fillColor,
                    fillOpacity: fillOpacity,
                    strokeColor: borderColor,
                    strokeOpacity: borderOpacity,
                  },
                });
                newMarkers.push(marker);
                bounds.extend(coords[0]);
              }
            } else {
              const polygon = new window.google.maps.Polygon({
                paths: coords,
                fillColor: fillColor,
                fillOpacity: fillOpacity,
                strokeColor: borderColor,
                strokeOpacity: borderOpacity,
              });
              newPolygons.push(polygon);
              coords.forEach(coord => bounds.extend(coord));
            }
          }
        });
      } else {
        selectedPlot.features.forEach(feature => {
          const coords = handleGeometry(feature.geometry);

          if (coords.length > 0) {
            let fillColor = '#000000';
            let borderColor = '#000000';
            let fillOpacity = 0.5;
            let borderOpacity = 1;

            if (feature.properties) {
              if (feature.properties.FillColor) {
                fillColor = feature.properties.FillColor;
              }
              if (feature.properties.BorderColor) {
                borderColor = feature.properties.BorderColor;
              }
              if (feature.properties.FillColorOpacity) {
                fillOpacity = parseFloat(feature.properties.FillColorOpacity);
              }
              if (feature.properties.BorderColorOpacity) {
                borderOpacity = parseFloat(feature.properties.BorderColorOpacity);
              }
            }

          if (feature.geometry.type === 'Point') {
              if (feature.properties.class === 'circle') {
                const circle = new window.google.maps.Circle({
                  center: coords[0],
                  radius: feature.properties.radius,
                  map: map,
                  fillColor: fillColor,
                  fillOpacity: fillOpacity,
                  strokeColor: borderColor,
                  strokeOpacity: borderOpacity,
                });
                newCircles.push(circle);
                bounds.extend(coords[0]);
              } else {
                const marker = new window.google.maps.Marker({
                  position: coords[0],
                  icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 5,
                    fillColor: fillColor,
                    fillOpacity: fillOpacity,
                    strokeColor: borderColor,
                    strokeOpacity: borderOpacity,
                  },
                });
                newMarkers.push(marker);
                bounds.extend(coords[0]);
              }
            } else {
              const polygon = new window.google.maps.Polygon({
                paths: coords,
                fillColor: fillColor,
                fillOpacity: fillOpacity,
                strokeColor: borderColor,
                strokeOpacity: borderOpacity,
              });
              newPolygons.push(polygon);
              coords.forEach(coord => bounds.extend(coord));
            }
          }
        });
      }

      if (newPolygons.length > 0) {
        newPolygons.forEach(polygon => polygon.setMap(map));
        setPolygons(newPolygons.concat(polygons));
      }

      if (newPolylines.length > 0) {
        newPolylines.forEach(polyline => polyline.setMap(map));
        setPolylines(newPolylines.concat(polylines));
      }

      if (newMarkers.length > 0) {
        newMarkers.forEach(marker => marker.setMap(map));
        setMarkers(newMarkers.concat(markers));
      }

      if (newCircles.length > 0) {
        newCircles.forEach(circle => circle.setMap(map));
        setCircles(newCircles.concat(circles));
      }

      fitMapToBounds(bounds);
    }
  }, [selectedPlot, handleGeometry, map, isLoaded]);


  const getLatLng = coord => {
    return { lat: coord[1], lng: coord[0] };
  };

  const getCenter = callback => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      { address: 'Belgrade, Serbia' }, // hardkodirano na BG
      (results, status) => {
        if (status === 'OK') {
          const { lat, lng } = results[0].geometry.location;
          callback({ lat: lat(), lng: lng() });
        } else {
          toast.error(
            'Geocode was not successful for the following reason: ' + status,
          );
        }
      },
    );
  };

  const fitMapToBounds = bounds => {
    if (mapRef.current) {
      const googleMapInstance = mapRef.current.state.map;
      googleMapInstance.fitBounds(bounds);
    }
  };

  useEffect(() => {
    if (map) {
      getCenter(setCenter);
    }
  }, [map]);

  useEffect(() => {
    if (drawingManagerRef.current) {
      // Remove the old listener
      window.google.maps.event.clearListeners(
        drawingManagerRef.current,
        'polygoncomplete',
      );

      // Attach the new listener
      window.google.maps.event.addListener(
        drawingManagerRef.current,
        'polygoncomplete',
        addObstacleMode ? onObstaclePoplygonComplete : onPolygonComplete,
      );
    }
  }, [addObstacleMode]);

  const options = {
    mapTypeControl: true, // Disable map/satellite toggle button
    streetViewControl: false, // Disable street view button
    mapTypeId: 'satellite',
  };

  if (isLoaded && !loadError) {
    options.mapTypeControlOptions = {
      position: window.google.maps.ControlPosition.TOP_RIGHT,
    };
  }
  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const initDrawingManager = mapInstance => {
    // if map is loaded from navigator analytics page, drawing manager is gonna be disabled.
    if (isNavigatorMap) {
      return;
    }

    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_LEFT,
        drawingModes: [
        ],
      },
      polygonOptions: {
        fillColor: 'black',
        strokeColor: '#0000FF',
      },
    });
    drawingManager.setMap(mapInstance);
    drawingManagerRef.current = drawingManager;

    if (drawingManagerRef.current) {
      window.google.maps.event.clearListeners(
        drawingManagerRef.current,
        'polygoncomplete',
      );
    }

    // posebni listeneri za svaki event, dodaj ostale ako zatrebaju
    // ne mogu se inicijalizovati zajedno, za svaki shape moraju posebno
    // ne moze drugacije da se izbegne da se okine listener za crtanje a crta se prepreka
    window.google.maps.event.addListener(
      drawingManager,
      'polygoncomplete',
      addObstacleMode ? onObstaclePoplygonComplete : onPolygonComplete,
    );
    window.google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      onOverlayComplete,
    );
  };

  useEffect(() => {
    if (map) {
      initDrawingManager(map);
      // Morace ovako da se inicijalizuje, da bi se fully kontrolisao
    }
  }, [map]);

  const onLoad = mapInstance => {
    setMap(mapInstance);
  };

  // save shape-a iz drawing managera
  const handleSave = async name => {
    const geoData = convertShapeToGeoJSON(drawnShape);
    const coordinates = [...geoData.features[0].geometry.coordinates];

    const geoJson = {
      type: 'FeatureCollection',
      properties: {
        class: 'parcel',
      },
      features: [
        {
          type: 'Feature',
          properties: {
            FillColor: 'black',
            FillOpacity: 0.3,
            BorderColor: 'black',
            BorderWidth: 1,
          },
          geometry: {
            type: 'Polygon',
            coordinates: coordinates,
          },
        },
      ],
    };
    const area = getAreaWithoutObstacles(geoJson);
    const size = parseFloat(area);

    try {
      let params = { name, size, organizationId: userOrgContextValue.id, geoData };
      await createMap(params);
      toast.success('Shape added successfully!');
      refetch();
      if (drawnShape) {
        drawnShape.setMap(null);
        setDrawnShape(null);
      }
    } catch (error) {
      if (drawnShape) {
        drawnShape.setMap(null);
        setDrawnShape(null);
      }
      toast.error(error.response.data.message);
    }
    setAddObstacleMode(false);
    setModalOpen(false);
    setAddObstacleMode(false);
    setObstacle(null);
  };

  const clearObstacleShape = () => {
    if (drawnShape) {
      drawnShape.setMap(null);
      setDrawnShape(null);
    }
  };

  const handleCancel = () => {
    if (drawnShape) {
      drawnShape.setMap(null);
      setDrawnShape(null);
    }
    setAddObstacleMode(false);
    setObstacle(null);
    setModalOpen(false);
  };

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps';

  return (
      <Page className={classes.root} title="Map component">
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            options={options}
            onLoad={onLoad}
            ref={mapRef}
          >
            {selectedPlot && (
              <>
                {selectedPlot.type === 'Polygon' && (
                  <Polygon
                    paths={getPolygonCoords(selectedPlot.coordinates)}
                    options={{
                      fillColor: selectedPlot?.properties?.FillColor,
                      fillOpacity: selectedPlot?.properties?.FillColorOp || 0.3,
                      strokeColor: selectedPlot?.properties?.BorderColor,
                      strokeWeight: selectedPlot?.properties?.BorderWidth || 1,
                    }}
                  />
                )}
                {selectedPlot.type === 'Feature' && (selectedPlot.properties?.class === 'circle' && (
                  <Circle
                    position={getLatLng(selectedPlot?.geometry?.coordinates)}
                    radius={selectedPlot?.properties.class}
                  />
                ))}
                {selectedPlot.type === 'Feature' && (
                  <Polygon
                    paths={getPolygonCoords(selectedPlot.coordinates)}
                    options={{
                      fillColor: selectedPlot?.properties?.FillColor,
                      fillOpacity: selectedPlot?.properties?.FillColor,
                      strokeColor: selectedPlot?.properties?.BorderColor,
                      strokeWeight: selectedPlot?.properties?.BorderWidth || 1,
                    }}
                  />
                )}
                {selectedPlot.type === 'Point' && (
                  <Marker
                    position={getLatLng(selectedPlot[0]?.geometry.coordinates)}
                    visible
                    options={{
                      zIndex: 999,
                    }}
                  />
                )}
                {selectedPlot.type === 'LineString' && (
                  <Polyline
                    path={selectedPlot.coordinates.map(getLatLng)}
                    options={{
                      fillColor: selectedPlot.properties.FillColor,
                      fillOpacity: selectedPlot.properties.FillColorOp || 0.3,
                      strokeColor: selectedPlot.properties.BorderColor,
                      strokeWeight: selectedPlot.properties.BorderWidth || 1,
                    }}
                  />
                )}
                {selectedPlot.type === 'MultiPoint' &&
                  selectedPlot.coordinates.map((coord, index) => (
                    <Marker
                      key={index}
                      position={getLatLng(coord)}
                    />
                  ))}
              </>
            )}
            {infoWindows}
            {legendData && isPlotSelected && isRxMapClicked && <Legend legendData={legendData} isRxMap/>}
          </GoogleMap>
        )}
        <DrawingModal
          open={modalOpen}
          onSave={handleSave}
          onCancel={handleCancel}
          addObstacleMode={addObstacleMode}
          selectedPlot={selectedPlot}
          refetch={refetch}
          plotData={plotData}
          obstacle={obstacle}
          setModalOpen={setModalOpen}
          setAddObstacleMode={setAddObstacleMode}
          setMap={setMap}
          clearObstacleShape={clearObstacleShape}
        />
      </Page>
  );
};

export default PlotsMap;
